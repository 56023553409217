import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.IE,
  locale: Locales['en-IE'],
  currency: Currencies.EUR,
  distanceUnit: DistanceUnit.km,
}

type PossibleLocales = keyof Pick<typeof Locales, 'en-IE'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'en-IE': INTL_DEFAULTS,
}
