import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    'a7565249-c343-4f22-87b9-2b883dc1fe05': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '001c750f-e7d0-42ee-8207-c99ef37bf329': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '866b0098-7483-4de6-a4d5-ab017f0003ce': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '8+',
  },
  productTypeDeliveryConfig: [
    {
      type: 'GLASSES_BUNDLE',
      expectedDeliveryDays: '15',
    },
    {
      type: 'NON_PRESCRIPTION_BUNDLE',
      expectedDeliveryDays: '15',
    },
    {
      type: 'CONTACT_LENSES',
      expectedDeliveryDays: '7',
    },
    {
      type: 'REGULAR',
      expectedDeliveryDays: '7',
    },
  ],
})
