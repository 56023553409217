import type { FormatterMap, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
} from '@grandvisionhq/www-next/formatters'

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createSimpleFormatter('book-appointment'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createAuthFormatters(),
  ...createMyAccountFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { login: 'login', shipping: 'shipping', overview: 'overview', payment: 'payment' },
  }),
  ...productDetailFormatters({
    accessories: 'eyecare-and-accessories',
    'contact-lenses': 'contact-lenses',
    frames: 'glasses',
    solutions: 'contact-lenses/solutions',
    sunglasses: 'sunglasses',
  }),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/login', 'checkout.login'],
  ['/checkout/shipping', 'checkout.shipping'],
  ['/checkout/overview', 'checkout.overview'],
  ['/checkout/payment', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/express-checkout', 'express-checkout'],

  ['/book-appointment/(.*)?', 'appointments.book'],
  ['/reschedule-appointment', 'appointments.reschedule'],
  ['/cancel-appointment', 'appointments.cancel'],
]
