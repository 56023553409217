import type { AppointmentFormTypes as Types } from '@grandvisionhq/appointments'

export const consents: Types.ConsentOptIn[] = [
  {
    title: 'appointments.consent.createAccount',
    value: 'createAccount',
    required: true,
    children: [
      {
        title: 'appointments.optIn.marketingEmails',
        value: 'marketingEmails',
      },
    ],
  },
]
export const optIns: Types.ConsentOptIn[] = []
