import getConfig from 'next/config'

import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type { CheckoutSettings, Routes } from '@grandvisionhq/checkout-v2'
import { AddressLineFormat } from '@grandvisionhq/checkout-v2'
import { Util as StoreUtils } from '@grandvisionhq/stores-v2'

interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

export const basePath = '/checkout'
export const routes: Routes = {
  login: {
    label: 'Account',
    loggedInLabel: 'Account',
    path: `${basePath}/login`,
  },
  shipping: {
    label: 'Delivery',
    path: `${basePath}/shipping`,
  },
  overview: {
    label: 'Overview',
    path: `${basePath}/overview`,
  },
  payment: {
    label: 'Payment',
    path: `${basePath}/payment`,
  },
}

export const getCheckoutConfig = ({ cart }: SettingsInput): CheckoutSettings => {
  return {
    account: {
      implicitAccountCreation: true,
      createAccountRequired: true,
    },
    consent: {
      implicitTermsAndConditions: true,
    },
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingFormFields: {
      billingAddress: [
        'salutation',
        'firstName',
        'lastName',
        'streetName',
        'city',
        'postalCode',
        'region',
      ],
      contactDetails: ['email', 'phone', 'dateOfBirth'],
    },
    storeFinder: {
      showUseMyLocation: true,
      searchWhitelist: ['storeName', 'postalCode', 'streetName', 'town'] as StoreUtils.SearchKey[],
    },
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: false,
      isServiceStoreEnabled: true,
    },
    serviceStore: {
      preselectFromShippingData: true,
    },
    addressLookup: {
      addressLineFormat: AddressLineFormat.streetNumber_streetName,
      shouldCombineAddress: true,
    },
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    payments: {
      applePay: {
        merchantName: 'Vision Express',
      },
    },
    routes,
    logoVariant: 'main',
  }
}
